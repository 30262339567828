<template>
    <v-card class="overflow-hidden">
        <v-card-actions
                style="background: gray"
        >
            <v-card-title class="white--text">
                Редактор блока информации
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn v-on:click="closeForm()">Закрыть</v-btn>
            <v-btn color="red" style="color: white" v-on:click="cleanForm()">
                Очистить
            </v-btn>
        </v-card-actions>

        <v-sheet class="pa-2">
            <v-row>
                <transition name="slide-fade" mode="in-out">
                    <v-col cols="6" v-if="!changingSettings">
                        <rich-editor-field v-model="title" v-if="enableRichEditor" title="Заголовок"></rich-editor-field>
                        <v-textarea v-model="title" label="Текст" v-else></v-textarea>
                        <v-switch v-model="enableRichEditor" color="green" label="Текстовый редактор"></v-switch>
                        <rich-editor-field v-model="description" title="Текст" v-if="enableRichEditor"></rich-editor-field>
                        <v-textarea v-model="description" label="Текст" v-else></v-textarea>
                    </v-col>

                </transition>

                <transition name="slide-fade" mode="out-in">
                    <v-col cols="6" v-if="changingSettings">
                        <v-card-title>Классы</v-card-title>
                        <v-text-field v-model="cssClass"></v-text-field>
                        <v-card-title>CSS свойства</v-card-title>
                        <div v-if="changingSettings" style="max-height: 40vh" class="overflow-y-auto overflow-x-hidden">
                            <div v-for="item of cssProps" :key="item[0]" >
                                <v-row align="center" justify="center">
                                    <v-col cols="4">
                                        <v-text-field label="Свойство" dense v-model="item[1]"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Значение" dense v-model="item[2]"></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-icon color="red" v-on:click="cssProps.splice(cssProps.indexOf(item), 1)">mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>

                        <v-row :justify="cssProps && cssProps.length > 0 ? 'space-between' : 'start'" class="mx-1">
                            <v-col cols="2">
                                <v-btn color="white" block elevation="0" v-on:click="cssProps.push([`key-${cssProps.length}-${Math.random() * 1000}`, '',''])">
                                    <v-icon color="green" >mdi-plus</v-icon>
                                    Добавить
                                </v-btn>
                            </v-col>
                            <v-col cols="2" v-if="cssProps && cssProps.length > 0">
                                <v-btn color="white" block elevation="0" v-on:click="cssProps = []">
                                    <v-icon color="red" >mdi-trash-can</v-icon>
                                    Удалить всё
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </transition>
                <v-col cols="6">
                    <v-card-title>Тип блока</v-card-title>

                    <v-btn-toggle v-model="selectedBlockType" label="Тип блока" class="d-flex flex-row overflow-auto">
                        <v-btn  v-for="[idx, value] of Object.entries(BLOCK_TYPES)" :key="idx" :value="idx" >
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-img :src="require(`../../assets/${value.name}`)" contain
                                           style="max-width: 60px; max-height: 100%;"
                                           v-bind="attrs"
                                           v-on="on"/>
                                </template>
                                <span>{{value.info}}</span>

                            </v-tooltip>
                        </v-btn>
                    </v-btn-toggle>
                    <v-card-title>
                        Изображения
                    </v-card-title>
                    <v-spacer/>
                    <v-card-actions class="d-flex flex-row align-start justify-center" v-for="image of images" :key="image">
                        <div style="width: 90%" >
                            <v-img class="pa-1" :src="getStatic(image)" style="max-height: 200px" contain alt="image here">
                            </v-img>
                        </div>
                        <v-icon large v-on:click.stop="deleteImage(image)" color="red">
                            mdi-close
                        </v-icon>

                    </v-card-actions>
                    <v-card-actions>
                        <v-file-input
                                outlined dense
                                placeholder="Выберите изображение"
                                accept="image/*" class="mx-2" v-model="selectedFile" />
                    </v-card-actions>
                </v-col>

            </v-row>
        </v-sheet>
        <v-card-actions
                style="background: gray"
        >
            <v-btn style="color: white; width: 30%" color="black" v-on:click="changingSettings = !changingSettings">
                <v-icon v-if="!changingSettings">mdi-chevron-left</v-icon>
                <span>{{changingSettings ? 'К текстам' : 'Настроить'}}</span>
                <v-icon v-if="changingSettings">mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn color="green" style="color: white; width: 70%" v-on:click="createUpdateBlock()">
                {{idx ? 'Обновить' : 'Добавить'}}
            </v-btn>

        </v-card-actions>
    </v-card>
</template>

<script>
    import RichEditorField from "../RichEditorField";
    import axios from "axios";
    import {getStatic, getURL} from "../../settings";
    import eventBus from "../../utils/eventBus";
    import {BLOCK_TYPES} from "../../utils/blockTypes";

    export default {
        name: "BlockDialog",
        components: {RichEditorField},
        props: ['idx'],
        data: () => {
            return {
                eventBus,
                TableMap: {
                    'Actions': 'Действия',
                    '@Block': 'Идентификатор',
                    'Title': 'Заголовок',
                    'Description': 'Описание',
                    'Content': 'Изображения',
                    'Type': 'Тип'
                },
                headers: [],
                items: [],
                selectedBlockType: '1',
                title: '',
                description: '',
                enableRichEditor: true,
                images: [],
                selectedFile: null,
                fileLink: '',
                BLOCK_TYPES,
                changingSettings: false,
                cssProps: [],
                cssClass: ''
            }
        },
        destroyed() {
            this.cleanForm();
            eventBus.$emit('block-not-changed');
        },
        mounted() {
            console.log('mounted with id: ', this.idx)

            if(this.idx)
                this.loadBlock();
        },
        watch: {
            idx: function(newIdx) {
                console.log('watch id: ', newIdx)
                if(newIdx) {
                    this.loadBlock();
                }
                else {
                    this.cleanForm();
                }
            },
            selectedFile(value) {
                if ( value ) {
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(this.selectedFile);
                    reader.onload = () => {
                        const blob = new Blob([reader.result]);
                        const formData = new FormData();
                        formData.append('image', blob);
                        formData.append('action', 'create');
                        formData.append('type', value.type);
                        formData.append('name', value.name);
                        axios.post(getURL('admin/content'), formData, {withCredentials:true})
                            .then(response=> {
                                if(Array.isArray(this.images)) {
                                    this.images.push(response.data);
                                } else {
                                    this.images[response.data] = response.data;
                                }
                                this.selectedFile = null;
                            })
                    }
                }
            }
        },
        methods: {
            loadBlock(){
                axios.get(getURL(`block/${this.idx}`)).then(resp => {
                    const item = resp.data;
                    this.images = item['Content'] || []
                    this.title = item['Title']
                    this.selectedBlockType = String(item['Type']);
                    this.description = item['Description'];
                    this.cssProps = this.unwrapProps(item['CSSProps']) || [];
                    this.cssClass = item['CSSClass'] || ''
                })
            },
            changeTitle(text) {
                this.title = text
            },
            changeDescription(text) {
                this.description = text
            },
            closeForm() {
                this.cleanForm();
                eventBus.$emit('block-not-changed')
            },
            deleteImage(image) {
                axios.post(getURL('admin/content'), {
                    action: 'delete',
                    link: image
                }, {withCredentials:true});
                if(Array.isArray(this.images)) {
                    this.images.splice(this.images.indexOf(image), 1);
                } else {
                    delete this.images[image];
                }
                axios.post(getURL('admin/blocks'), {
                    id: this.idx,
                    action: this.idx ? 'update' : 'create',
                    type: this.selectedBlockType,
                    title: this.title,
                    description: this.description,
                    images: this.images
                }, {withCredentials: true});
            },
            loadBlocks() {
                this.items = [];
                this.headers = [];
                axios.get(getURL('admin/blocks', {withCredentials: true}))
                    .then(response => {
                        const resp_items = response.data;
                        if (resp_items && resp_items.length > 0) {
                            const resp_keys = Object.keys(resp_items[0]);
                            for(const key of resp_keys) {
                                this.headers.push({text: this.TableMap[key] || key, value: key })
                            }
                        }

                        for(const item of resp_items) {
                            let newItem = item;
                            newItem['Properties'] = JSON.stringify(item['Properties'])
                            this.items.push(newItem);
                        }
                        this.headers.push({ text: 'Действия', value: 'actions', sortable: false });

                    });
            },
            createUpdateBlock() {
                axios.post(getURL('admin/blocks'), {
                    id: this.idx,
                    action: this.idx ? 'update' : 'create',
                    type: this.selectedBlockType,
                    title: this.title,
                    description: this.description,
                    images: this.images,
                    cssProps: this.wrapProps(),
                    cssClass: this.cssClass
                }, {withCredentials: true})
                    .then((resp)=> {
                        const data = resp.data;
                        const idx = data ? data['@Block'] : null;
                        this.cleanForm();
                        eventBus.$emit(this.idx ? 'block-updated' : 'block-created', idx );
                    });
            },
            wrapProps() {
                let object = {};
                this.cssProps.forEach(propItem => {
                    object[propItem[1]] = propItem[2];
                })
                return object;
            },
            unwrapProps(props) {
                let cssProps = [];
                console.log(Object.entries(props))
                for(const [key, value] of Object.entries(props)) {
                    cssProps.push([`key-${key}-${Math.random() * 1000}`, key, value]);
                }
                return cssProps;
            },
            cleanForm() {
                this.selectedBlockType = '1';
                this.title = '';
                this.description = ''
                this.images = [];
                this.selectedFile = null;
                this.cssProps = [];
                this.cssClass = '';
                this.changingSettings = false;
            },
            editItem(item) {
                this.images = item['Content'] || []
                this.title = item['Title']
                this.selectedBlockType = String(item['Type']);
                this.description = item['Description'];
            },
            deleteItem(item) {
                axios.post(getURL('admin/blocks'), {
                    action: 'delete',
                    id: item['@Block']
                }, {withCredentials: true})
                    .then( ()=> {
                        eventBus.$emit('blocks-updated', this.idx ? this.idx : null);
                    });
            },
            getURL, getStatic
        },
        beforeDestroy() {
            this.cleanForm();
        }
    }
</script>

<style>
    .slide-fade-enter-active {
        transition: all .6s;
    }
    .slide-fade-leave-active {
        transition: all .3s;
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(200px);
        opacity: 0;
    }
</style>
